import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import Slider from "react-slick";
// import TestimonialStyleThreeImg1 from "../../assets/images/testimonial/testimonial-2.png";
import TestimonialStyleThreeImg2 from "../../assets/images/testimonial/5.jpg";
import TestimonialStyleThreeImg3 from "../../assets/images/testimonial/2.jpg";
import TestimonialStyleThreeImg4 from "../../assets/images/testimonial/4.jpg";

const TestimonialThreeCommentData = [
	{
		id: uuidv4(),
		comment:
			"I’ve worked with Ramos Landscaping Services since moving into my new home in August 2022. As a new build, the landscaping was very basic. Working with Al and his team, I am realizing my dream of turning my wild backyard into a private courtyard that is beautiful, functional and enjoyable. Al is a creative and meticulous landscaper with a team of dedicated craftsmen who do beautiful stonework and can build anything! The work consistently reflects Al’s high standards, attention to detail and creativity. Every project has exceeded my expectations! ",
	},

	{
		id: uuidv4(),
		comment:
			"I was very pleased with the professionalism and craftsmanship of Ramos Landscaping! Not only was the work excellent, but Al and his team are very friendly and that made the experience even more enjoyable! Thank you, Ramos Landscaping!",
	},

	// {
	// 	id: uuidv4(),
	// 	comment:
	// 		"This is a testimony from Lyn. \nLorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.",
	// },

	// {
	// 	id: uuidv4(),
	// 	comment:
	// 		"Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.",
	// },
];

const TestimonialThreeAuthorData = [
	{
		id: uuidv4(),
		img: TestimonialStyleThreeImg4,
		name: "Cathy Nielson",
		position: "Homeowner",
	},

	{
		id: uuidv4(),
		img: TestimonialStyleThreeImg4,
		name: "Ali Banchiere",
		position: "Homeowner",
	},

	// {
	// 	id: uuidv4(),
	// 	img: TestimonialStyleThreeImg2,
	// 	name: "Lyn McFarland",
	// 	position: "Entrepreneur",
	// },

	// {
	// 	id: uuidv4(),
	// 	img: TestimonialStyleThreeImg3,
	// 	name: "Zach Broch",
	// 	position: "Entrepreneur",
	// },
];

const TestimonialThreeCommentItem = ({ comment }) => {
	return (
		<>
			<div className="single-testimonial-style-3">
				<p>{comment}</p>
			</div>
		</>
	);
};

const TestimonialThreeAuthorItem = ({ img, name, position }) => {
	return (
		<>
			<div className="single-testimonial-style-3-navigator">
				{/* <img src={img} alt="" /> */}
				<h5>{name}</h5>
				<span>{position}</span>
			</div>
		</>
	);
};

const TestimonialStyleThree = () => {
	const [nav1, setNav1] = useState();
	const [nav2, setNav2] = useState();

	return (
		<>
			<div className="contact-testimonial pb-110">
				<div className="container">
					<div className="row d-flex align-items-center">
						{/* <div className="col-xl-6 col-lg-6 col-md-12">
							<div className="testimonial-img-1 ml-50">
								<img src={TestimonialStyleThreeImg4} alt="" />
							</div>
						</div> */}
						<div className="col-md-12">
							<h2>Satisfied Customers</h2>

							<Slider
								asNavFor={nav2}
								ref={(slider1) => setNav1(slider1)}
								slidesToShow={1}
								slidesToScroll={1}
								arrows={false}
								fade={true}
								className="testimonial-style-3"
							>
								{TestimonialThreeCommentData.map((data) => (
									<TestimonialThreeCommentItem
										key={data.id}
										comment={data.comment}
									/>
								))}
							</Slider>
							<Slider
								asNavFor={nav1}
								ref={(slider2) => setNav2(slider2)}
								slidesToShow={1}
								slidesToScroll={1}
								swipeToSlide={true}
								dots={false}
								arrows={true}
								centerMode={false}
								infinite={true}
								autoplay={true}
								autoplaySpeed={10000}
								prevArrow={
									<button
										type="button"
										className="slick-prev"
									>
										<FaChevronLeft />
									</button>
								}
								nextArrow={
									<button
										type="button"
										className="slick-next"
									>
										<FaChevronRight />
									</button>
								}
								className="testimonial-style-3-navigator mt-40"
							>
								{TestimonialThreeAuthorData.map((data) => (
									<TestimonialThreeAuthorItem
										key={data.id}
										img={data.img}
										name={data.name}
										position={data.position}
										fbLink={data.fbLink}
										twitterLink={data.twitterLink}
										instagramLink={data.instagramLink}
										pinterestLink={data.pinterestLink}
									/>
								))}
							</Slider>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TestimonialStyleThree;
