import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FaAngleDoubleRight } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
// import ProjectStyleOneImg1 from "../../assets/images/project/garden-care.jpg";
// import ProjectStyleOneImg2 from "../../assets/images/project/tree-plantation.jpg";
// import ProjectStyleOneImg3 from "../../assets/images/project/branch-cutting.jpg";
import stonework10 from "../../assets/images/project/stonework10.jpg";
import gardenBed2 from "../../assets/images/project/gardenBeds2.jpg";
import painting2 from "../../assets/images/project/painting2.jpg";
import drainage2 from "../../assets/images/project/drainage2.JPG";
import lawncare2 from "../../assets/images/project/lawncare2.JPG";
import fence2 from "../../assets/images/project/fence2.jpg";
import stonework4 from "../../assets/images/project/stonework4.jpg";
import stonework1 from "../../assets/images/project/stonework1.jpg";
import mulch2 from "../../assets/images/project/mulch2.jpeg";
import placeholder from "../../assets/images/project/4.jpg";
import trim2 from "../../assets/images/project/trim2.jpg";
import pressure1 from "../../assets/images/project/pressure1.jpg";
import { appContext } from "../../context/context";

const ProjectStyleOneData = [
	{
		id: uuidv4(),
		img: stonework1,
		heading: "Stonework",
		// description:
		// 	"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
		btnLink: "/project-details",
		category: "stonework",
	},
	{
		id: uuidv4(),
		img: gardenBed2,
		heading: "Garden Beds",
		btnLink: "/project-details",
		category: "garden",
	},
	{
		id: uuidv4(),
		img: painting2,
		heading: "Painting",
		btnLink: "/project-details",
		category: "paint",
	},
	{
		id: uuidv4(),
		img: drainage2,
		heading: "Drainage Solutions",
		btnLink: "/project-details",
		category: "drain",
	},
	{
		id: uuidv4(),
		img: lawncare2,
		heading: "Lawn Care",
		btnLink: "/project-details",
		category: "lawn",
	},
	{
		id: uuidv4(),
		img: fence2,
		heading: "Fence Construction",
		btnLink: "/project-details",
		category: "fence",
	},
	{
		id: uuidv4(),
		img: stonework4,
		heading: "Landscape Design",
		btnLink: "/project-details",
		category: "landscape",
	},
	{
		id: uuidv4(),
		img: pressure1,
		heading: "Pressure Washing",
		btnLink: "/project-details",
		category: "pressure",
	},
	{
		id: uuidv4(),
		img: mulch2,
		heading: "Mulching",
		btnLink: "/project-details",
		category: "mulch",
	},
	{
		id: uuidv4(),
		img: trim2,
		heading: "Tree Trimming",
		btnLink: "/project-details",
		category: "trim",
	},
	{
		id: uuidv4(),
		img: stonework10,
		heading: "Retaining Wall Construction",
		btnLink: "/project-details",
		category: "retaining",
	},
];

const ProjectStyleOneItem = ({
	img,
	heading,
	description,
	btnLink,
	category,
}) => {
	const { setProjectStyle } = useContext(appContext);
	return (
		<>
			<div className="col-xl-4 col-lg-4 col-md-4">
				<div className="single-project">
					<div
						className="single-project-img bg-property bg-hover-style-1"
						style={{ backgroundImage: `url(${img})` }}
						onClick={(e) => {
							setProjectStyle(category);
						}}
					>
						<div className="single-project-img-icon">
							<Link
							// to={btnLink}
							>
								<FaAngleDoubleRight />
							</Link>
						</div>
					</div>
					<div className="single-project-content">
						<h3 style={{ fontSize: 25 }}>{heading}</h3>
						<p>{description}</p>
					</div>
				</div>
			</div>
		</>
	);
};

const ProjectStyleOne = () => {
	return (
		<>
			<div className="project-area pt-110 pb-110">
				<div className="container">
					<div className="row">
						<div className="col-xl-12">
							<div className="section-title text-center mb-70">
								{/* <span>Our Project</span> */}
								<h2>Our Successful Projects</h2>
							</div>
						</div>
					</div>
					<div className="row mb-60">
						{ProjectStyleOneData.map((data) => (
							<ProjectStyleOneItem
								key={data.id}
								img={data.img}
								heading={data.heading}
								description={data.description}
								btnLink={data.btnLink}
								category={data.category}
							/>
						))}
					</div>
					{/* <div className="row">
						<div className="col-12">
							<div className="project-btn text-center">
								<Link to="/" className="l-btn">
									More Project
								</Link>
							</div>
						</div>
					</div> */}
				</div>
			</div>
		</>
	);
};

export default ProjectStyleOne;
