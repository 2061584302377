import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import Icon_growing from "../../assets/images/service/growing.png";

import Icon_tree from "../../assets/images/service/tree.png";
import Icon_rubbish from "../../assets/images/service/rubbish.png";
import Icon_stone from "../../assets/images/service/stone.png";
import Icon_fence from "../../assets/images/service/fence.png";
import Icon_drain from "../../assets/images/service/drain.png";
import Icon_paint from "../../assets/images/service/paint.png";
import Icon_trim from "../../assets/images/service/trim.png";
import Icon_pressure from "../../assets/images/service/pressure-washer.png";
import Icon_flagstone from "../../assets/images/service/flagstone.png";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const ServiceOneData = [
	{
		id: uuidv4(),
		heading: "Landscape Design",
		description:
			"Our landscape design service is dedicated to creating a beautiful and functional outdoor space that suits your individual style and needs. We work closely with you to understand your vision and transform it into a reality",
		img: Icon_tree,
		serviceLink: "/gallery",
	},
	{
		id: uuidv4(),
		heading: "Lawn Care",
		description:
			"Our lawn care service is designed to keep your lawn healthy, green, and lush all year round. We provide a range of services, including mowing, trimming, edging, fertilization and weed control. Our team of experienced professionals uses the latest techniques and equipment to ensure that your lawn is always looking its best",
		img: Icon_rubbish,
		serviceLink: "/",
	},
	{
		id: uuidv4(),
		heading: "Retaining Wall Construction",
		description:
			"Our retaining wall construction service is dedicated to providing a strong and durable solution for retaining soil and creating functional outdoor spaces. We offer designs using stone or concrete to best match your style and needs. We take pride in our attention to detail and quality craftsmanship, ensuring that your retaining wall is not only functional but also aesthetically pleasing",
		img: Icon_stone,
		serviceLink: "/",
	},
	{
		id: uuidv4(),
		heading: "Drainage Solutions",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
		img: Icon_drain,
		serviceLink: "/",
	},
	{
		id: uuidv4(),
		heading: "Garden Beds",
		description:
			"Our garden bed construction service is designed to provide you with a beautiful and functional garden space that enhances the beauty and value of your property. We offer a range of materials and designs to match your style and needs, including raised garden beds, mulch beds and natural stone edging. With our garden bed construction service, you can enjoy the benefits of fresh produce and beautiful flowers right outside your home",
		img: Icon_growing,
		serviceLink: "/",
	},
	{
		id: uuidv4(),
		heading: "Stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
		img: Icon_flagstone,
		serviceLink: "/",
		category: "stonework",
	},
	{
		id: uuidv4(),
		heading: "Fence Construction",
		description:
			"Our fence construction service provides top-quality fence installation and repair services to homeowners and businesses. We specialize in building durable and attractive fences using the highest quality materials. Our team of experienced professionals is equipped with the tools and expertise necessary to complete any fencing project, whether it's a simple repair or a complete installation.",
		img: Icon_fence,
		serviceLink: "/",
	},
	{
		id: uuidv4(),
		heading: "Painting",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
		img: Icon_paint,
		serviceLink: "/",
	},
	{
		id: uuidv4(),
		heading: "Tree Trimming",
		description:
			"Our tree trimming service provides professional and efficient trimming services for residential and commercial properties. Our skilled arborists use the latest techniques and equipment to trim your trees to promote healthy growth and maintain a beautiful appearance. We take great care to ensure the safety of your property and surrounding landscape during the trimming process",
		img: Icon_trim,
		serviceLink: "/",
	},
	{
		id: uuidv4(),
		heading: "Pressure Washing",
		description:
			"Our pressure washing services combine high-pressure water and cleaning solutions to remove dirt, grime, mold, and other stains from a variety of surfaces, including siding, decks, patios, sidewalks, and driveways. Pressure washing your property’s surfaces will leave it looking brand new",
		img: Icon_pressure,
		serviceLink: "/",
	},
];

const ServiceOneItem = ({ img, heading, description, serviceLink }) => {
	let shortenedDescription = description.substring(0, 150);
	const [readMore, setReadMore] = useState(false);
	return (
		<>
			<div className="col-md-6 single-service-container">
				<div className="single-service">
					<div className="service-icon">
						<img src={img} alt="Service Icon" />
					</div>
					<h3>{heading}</h3>
					<p>
						{/* {readMore ? description : shortenedDescription + "..."} */}
						{description}
					</p>
					<a href={serviceLink}>
						Read More <FaLongArrowAltRight />
					</a>
					{/* <Link onClick={() => setReadMore(!readMore)}>
						{readMore ? "See Less" : "Read More"}{" "}
						<FaLongArrowAltRight />
					</Link> */}
				</div>
			</div>
		</>
	);
};

const ServiceStyleOne = () => {
	return (
		<>
			<div className="service-area theme-bg-4 pt-110 pb-110">
				<div className="container">
					<div className="row">
						<div className="col-xl-12">
							<div className="section-title text-center mb-70">
								{/* <span>Our Services</span> */}
								<h2>
									Why will you choose our <br /> services?
								</h2>
							</div>
						</div>
					</div>
					<div className="row">
						{ServiceOneData.map((data) => (
							<ServiceOneItem
								key={data.id}
								img={data.img}
								heading={data.heading}
								description={data.description}
								serviceLink={data.serviceLink}
							/>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default ServiceStyleOne;
