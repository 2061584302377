import React from "react";
import { Link } from "react-router-dom";
import LogoImg from "../../assets/images/ramos-llc-logos/PNG/no-border-02-cropped.png";

const Logo = () => {
	return (
		<div className="logo">
			<Link to="/">
				<img
					className="logoImg"
					src={LogoImg}
					alt="Logo"
					loading="lazy"
				/>
			</Link>
		</div>
	);
};

export default Logo;
