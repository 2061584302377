import React, { useContext, useEffect } from "react";
import HeaderTop from "../common/header/HeaderTop";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import breadcrumbBg from "../assets/images/breadcrumb/DSC03998.JPG";
import ProjectStyleFour from "../components/project/ProjectStyleFour";
import ProjectStyleOne from "../components/project/ProjectStyleOne";
import { galleryImages } from "../components/project/ProjectStyleFour";

import WhyChoseUsTwo from "../components/why-choose-us/WhyChoseUsTwo";
import FooterOne from "../common/footer/FooterOne";
import CtaOne from "../components/cta/CtaOne";
import { appContext } from "../context/context";
const Gallery = () => {
	// const [projectStyle, setProjectStyle] = useState("all");
	// const [projectStyleFourItem, setProjectStyleFourItem] = useState([]);

	const { projectStyle, setProjectStyleFourItem } = useContext(appContext);

	useEffect(() => {
		projectStyle === "all"
			? setProjectStyleFourItem(galleryImages)
			: setProjectStyleFourItem(
					galleryImages.filter(
						(data) => data.category === projectStyle
					)
			  );
		window.scrollTo(0, 0);
	}, [projectStyle]);
	return (
		<>
			<HeaderTop />
			<HeaderBottomOne />
			<Breadcrumb
				breadcrumbBg={breadcrumbBg}
				heading="Services"
				// description="We are available for 24/7 for Garden & Landscaping Services"
				currentPage="Services"
			/>
			{projectStyle === "all" ? (
				<ProjectStyleOne />
			) : (
				<ProjectStyleFour />
			)}
			<CtaOne />
			<WhyChoseUsTwo />
			<FooterOne />
		</>
	);
};

export default Gallery;
