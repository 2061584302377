import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { FaLink, FaSearchPlus } from "react-icons/fa";
import Lightbox from "yet-another-react-lightbox";
import stonework1 from "../../assets/images/project/stonework1.jpg";
import stonework2 from "../../assets/images/project/stonework2.jpg";
import stonework3 from "../../assets/images/project/stonework3.jpg";
import stonework4 from "../../assets/images/project/stonework4.jpg";
import stonework6 from "../../assets/images/project/stonework6.jpg";
import stonework8 from "../../assets/images/project/stonework8.jpg";
import stonework9 from "../../assets/images/project/stonework9.jpg";
import stonework10 from "../../assets/images/project/stonework10.jpg";
import stonework11 from "../../assets/images/project/stonework11.jpg";
import stonework12 from "../../assets/images/project/stonework12.jpg";
import stonework13 from "../../assets/images/project/stonework13.jpg";
import stonework14 from "../../assets/images/project/stonework14.jpg";
import stonework15 from "../../assets/images/project/stonework15.jpg";
import stonework16 from "../../assets/images/project/stonework16.jpg";
import stonework17 from "../../assets/images/project/stonework17.jpg";
import stonework18 from "../../assets/images/project/stonework18.jpg";
import stonework19 from "../../assets/images/project/stonework19.jpg";
import stonework20 from "../../assets/images/project/stonework20.jpg";
import stonework21 from "../../assets/images/project/stonework21.jpg";
import stonework22 from "../../assets/images/project/stonework22.jpg";
import stonework23 from "../../assets/images/project/stonework23.jpg";
import stonework24 from "../../assets/images/project/stonework24.jpeg";

import retain1 from "../../assets/images/project/retain1.jpg";
import retain2 from "../../assets/images/project/retain2.jpg";
import retain3 from "../../assets/images/project/retain3.jpg";
import retain4 from "../../assets/images/project/retain4.jpg";
import retain5 from "../../assets/images/project/retain5.jpg";
import retain6 from "../../assets/images/project/retain6.jpg";
import retain7 from "../../assets/images/project/retain7.jpg";

import gardenBed1 from "../../assets/images/project/gardenBeds1.jpg";
import gardenBed2 from "../../assets/images/project/gardenBeds2.jpg";
import gardenBed3 from "../../assets/images/project/gardenBeds3.jpg";
import gardenBed4 from "../../assets/images/project/gardenBed4.jpg";
import gardenBed5 from "../../assets/images/project/gardenBed5.jpg";
import painting1 from "../../assets/images/project/painting1.jpg";
import painting2 from "../../assets/images/project/painting2.jpg";
import painting3 from "../../assets/images/project/painting3.jpg";
import painting4 from "../../assets/images/project/painting4.jpg";
import painting5 from "../../assets/images/project/painting5.jpg";
import painting6 from "../../assets/images/project/painting6.jpg";
import painting7 from "../../assets/images/project/painting7.jpg";
import painting8 from "../../assets/images/project/painting8.jpg";
import painting9 from "../../assets/images/project/painting9.jpg";
import painting10 from "../../assets/images/project/painting10.jpg";
import painting11 from "../../assets/images/project/painting11.jpg";
import painting12 from "../../assets/images/project/painting12.jpg";
import painting13 from "../../assets/images/project/painting13.jpg";
import painting14 from "../../assets/images/project/painting14.jpg";
import painting15 from "../../assets/images/project/painting15.jpg";
import painting16 from "../../assets/images/project/painting16.jpg";
import drainage1 from "../../assets/images/project/drainage1.JPG";
import drainage2 from "../../assets/images/project/drainage2.JPG";
import drainage3 from "../../assets/images/project/drainage3.jpg";
import drainage4 from "../../assets/images/project/drainage4.jpg";
import drainage5 from "../../assets/images/project/drainage5.jpg";
import drainage6 from "../../assets/images/project/drainage6.jpg";
import drainage7 from "../../assets/images/project/drainage7.jpg";
import drainage8 from "../../assets/images/project/drainage8.jpg";
import drainage9 from "../../assets/images/project/drainage9.jpg";
import drainage10 from "../../assets/images/project/drainage10.jpg";
import drainage11 from "../../assets/images/project/drainage11.jpg";
import drainage12 from "../../assets/images/project/drainage12.jpg";
import drainage13 from "../../assets/images/project/drainage13.jpg";
import drainage14 from "../../assets/images/project/drainage14.jpg";
import drainage15 from "../../assets/images/project/drainage15.jpg";

import fence1 from "../../assets/images/project/fence1.jpg";
import fence2 from "../../assets/images/project/fence2.jpg";
import fence3 from "../../assets/images/project/stonework5.jpg";
import lawncare1 from "../../assets/images/project/lawncare1.JPG";
import lawncare2 from "../../assets/images/project/lawncare2.JPG";
import lawncare3 from "../../assets/images/project/lawncare3.JPG";
import lawncare4 from "../../assets/images/project/lawncare4.JPG";
import lawncare5 from "../../assets/images/project/lawncare5.JPG";
import lawncare6 from "../../assets/images/project/lawncare6.JPG";
import lawncare7 from "../../assets/images/project/lawncare7.JPG";
import lawncare9 from "../../assets/images/project/lawncare9.jpeg";
import lawncare10 from "../../assets/images/project/lawncare10.jpeg";
import lawncare11 from "../../assets/images/project/lawncare11.jpeg";
import lawncare12 from "../../assets/images/project/lawncare12.jpeg";

import mulch1 from "../../assets/images/project/mulch1.jpeg";
import mulch2 from "../../assets/images/project/mulch2.jpeg";
import mulch3 from "../../assets/images/project/mulch3.jpeg";
import mulch4 from "../../assets/images/project/mulch4.jpg";
import mulch5 from "../../assets/images/project/mulch5.jpg";
import drain_stone_retain from "../../assets/images/project/drain_stone_retain.jpg";
import stone_retain from "../../assets/images/project/stone_retain.jpeg";

import trim1 from "../../assets/images/project/trim1.jpg";
import trim2 from "../../assets/images/project/trim2.jpg";
import trim3 from "../../assets/images/project/trim3.jpg";
import trim4 from "../../assets/images/project/trim4.jpeg";
import trim5 from "../../assets/images/project/trim5.jpeg";
import trim6 from "../../assets/images/project/trim6.jpeg";
import trim7 from "../../assets/images/project/trim7.jpeg";

import pressure1 from "../../assets/images/project/pressure1.jpg";
import pressure2 from "../../assets/images/project/pressure2.jpg";

import trimTest from "../../assets/images/project/trimTest.jpg";

import placeholder from "../../assets/images/hero/1.jpg";
import { Bounce } from "react-reveal";

import { appContext } from "../../context/context";

export const galleryImages = [
	{
		id: uuidv4(),
		img: retain1,
		heading: "Retaining Wall Construction",
		imgPopup: retain1,
		category: "retaining",
		description:
			"Our retaining wall construction service is dedicated to providing a strong and durable solution for retaining soil and creating functional outdoor spaces. We offer designs using stone or concrete to best match your style and needs. We take pride in our attention to detail and quality craftsmanship, ensuring that your retaining wall is not only functional but also aesthetically pleasing",
	},
	{
		id: uuidv4(),
		img: retain2,
		heading: "Retaining Wall Construction",
		imgPopup: retain2,
		category: "retaining",
		description:
			"Our retaining wall construction service is dedicated to providing a strong and durable solution for retaining soil and creating functional outdoor spaces. We offer designs using stone or concrete to best match your style and needs. We take pride in our attention to detail and quality craftsmanship, ensuring that your retaining wall is not only functional but also aesthetically pleasing",
	},
	{
		id: uuidv4(),
		img: retain3,
		heading: "Retaining Wall Construction",
		imgPopup: retain3,
		category: "retaining",
		description:
			"Our retaining wall construction service is dedicated to providing a strong and durable solution for retaining soil and creating functional outdoor spaces. We offer designs using stone or concrete to best match your style and needs. We take pride in our attention to detail and quality craftsmanship, ensuring that your retaining wall is not only functional but also aesthetically pleasing",
	},
	{
		id: uuidv4(),
		img: retain4,
		heading: "Retaining Wall Construction",
		imgPopup: retain4,
		category: "retaining",
		description:
			"Our retaining wall construction service is dedicated to providing a strong and durable solution for retaining soil and creating functional outdoor spaces. We offer designs using stone or concrete to best match your style and needs. We take pride in our attention to detail and quality craftsmanship, ensuring that your retaining wall is not only functional but also aesthetically pleasing",
	},
	{
		id: uuidv4(),
		img: retain5,
		heading: "Retaining Wall Construction",
		imgPopup: retain5,
		category: "retaining",
		description:
			"Our retaining wall construction service is dedicated to providing a strong and durable solution for retaining soil and creating functional outdoor spaces. We offer designs using stone or concrete to best match your style and needs. We take pride in our attention to detail and quality craftsmanship, ensuring that your retaining wall is not only functional but also aesthetically pleasing",
	},
	{
		id: uuidv4(),
		img: retain6,
		heading: "Retaining Wall Construction",
		imgPopup: retain6,
		category: "retaining",
		description:
			"Our retaining wall construction service is dedicated to providing a strong and durable solution for retaining soil and creating functional outdoor spaces. We offer designs using stone or concrete to best match your style and needs. We take pride in our attention to detail and quality craftsmanship, ensuring that your retaining wall is not only functional but also aesthetically pleasing",
	},
	{
		id: uuidv4(),
		img: retain7,
		heading: "Retaining Wall Construction",
		imgPopup: retain7,
		category: "retaining",
		description:
			"Our retaining wall construction service is dedicated to providing a strong and durable solution for retaining soil and creating functional outdoor spaces. We offer designs using stone or concrete to best match your style and needs. We take pride in our attention to detail and quality craftsmanship, ensuring that your retaining wall is not only functional but also aesthetically pleasing",
	},
	{
		id: uuidv4(),
		img: drain_stone_retain,
		heading: "Retaining Wall Construction",
		imgPopup: drain_stone_retain,
		category: "retaining",
		description:
			"Our retaining wall construction service is dedicated to providing a strong and durable solution for retaining soil and creating functional outdoor spaces. We offer designs using stone or concrete to best match your style and needs. We take pride in our attention to detail and quality craftsmanship, ensuring that your retaining wall is not only functional but also aesthetically pleasing",
	},
	{
		id: uuidv4(),
		img: stone_retain,
		heading: "Retaining Wall Construction",
		imgPopup: stone_retain,
		category: "retaining",
		description:
			"Our retaining wall construction service is dedicated to providing a strong and durable solution for retaining soil and creating functional outdoor spaces. We offer designs using stone or concrete to best match your style and needs. We take pride in our attention to detail and quality craftsmanship, ensuring that your retaining wall is not only functional but also aesthetically pleasing",
	},
	{
		id: uuidv4(),
		img: stone_retain,
		heading: "Stonework",
		imgPopup: stone_retain,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework1,
		heading: "Stonework",
		imgPopup: stonework1,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},

	{
		id: uuidv4(),
		img: stonework2,
		heading: "Stonework",
		imgPopup: stonework2,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework2,
		heading: "Garden Beds",
		imgPopup: stonework2,
		category: "garden",
		description:
			"Our garden bed construction service is designed to provide you with a beautiful and functional garden space that enhances the beauty and value of your property. We offer a range of materials and designs to match your style and needs, including raised garden beds, mulch beds and natural stone edging. With our garden bed construction service, you can enjoy the benefits of fresh produce and beautiful flowers right outside your home",
	},
	{
		id: uuidv4(),
		img: stonework2,
		heading: "Fence Construction",
		imgPopup: stonework2,
		category: "fence",
		description:
			"Our fence construction service provides top-quality fence installation and repair services to homeowners and businesses. We specialize in building durable and attractive fences using the highest quality materials. Our team of experienced professionals is equipped with the tools and expertise necessary to complete any fencing project, whether it's a simple repair or a complete installation.",
	},
	{
		id: uuidv4(),
		img: stonework3,
		heading: "Stonework",
		imgPopup: stonework3,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework3,
		heading: "Fence Construction",
		imgPopup: stonework3,
		category: "fence",
		description:
			"Our fence construction service provides top-quality fence installation and repair services to homeowners and businesses. We specialize in building durable and attractive fences using the highest quality materials. Our team of experienced professionals is equipped with the tools and expertise necessary to complete any fencing project, whether it's a simple repair or a complete installation.",
	},
	{
		id: uuidv4(),
		img: stonework4,
		heading: "Stonework",
		imgPopup: stonework4,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework4,
		heading: "Landscape Design",
		imgPopup: stonework4,
		category: "landscape",
		description:
			"Our landscape design service is dedicated to creating a beautiful and functional outdoor space that suits your individual style and needs. We work closely with you to understand your vision and transform it into a reality",
	},
	{
		id: uuidv4(),
		img: stonework2,
		heading: "Landscape Design",
		imgPopup: stonework2,
		category: "landscape",
		description:
			"Our landscape design service is dedicated to creating a beautiful and functional outdoor space that suits your individual style and needs. We work closely with you to understand your vision and transform it into a reality",
	},
	{
		id: uuidv4(),
		img: stonework6,
		heading: "Stonework",
		imgPopup: stonework6,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework8,
		heading: "Stonework",
		imgPopup: stonework8,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework9,
		heading: "Stonework",
		imgPopup: stonework9,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework10,
		heading: "Stonework",
		imgPopup: stonework10,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework10,
		heading: "Retaining Wall Construction",
		imgPopup: stonework10,
		category: "retaining",
		description:
			"Our retaining wall construction service is dedicated to providing a strong and durable solution for retaining soil and creating functional outdoor spaces. We offer designs using stone or concrete to best match your style and needs. We take pride in our attention to detail and quality craftsmanship, ensuring that your retaining wall is not only functional but also aesthetically pleasing",
	},
	{
		id: uuidv4(),
		img: stonework11,
		heading: "Stonework",
		imgPopup: stonework11,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework12,
		heading: "Stonework",
		imgPopup: stonework12,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework13,
		heading: "Stonework",
		imgPopup: stonework13,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework14,
		heading: "Stonework",
		imgPopup: stonework14,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework15,
		heading: "Stonework",
		imgPopup: stonework15,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework16,
		heading: "Stonework",
		imgPopup: stonework16,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework17,
		heading: "Stonework",
		imgPopup: stonework17,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework18,
		heading: "Stonework",
		imgPopup: stonework18,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework19,
		heading: "Stonework",
		imgPopup: stonework19,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework20,
		heading: "Stonework",
		imgPopup: stonework20,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework21,
		heading: "Stonework",
		imgPopup: stonework21,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework22,
		heading: "Stonework",
		imgPopup: stonework22,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework23,
		heading: "Stonework",
		imgPopup: stonework23,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: stonework24,
		heading: "Stonework",
		imgPopup: stonework24,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},
	{
		id: uuidv4(),
		img: drain_stone_retain,
		heading: "Stonework",
		imgPopup: drain_stone_retain,
		category: "stonework",
		description:
			"Stonework is the skilled craft of cutting, shaping, and arranging natural stones to create functional and aesthetically pleasing structures. This includes constructing stone walls, pathways, patios and garden features. Stonework is a durable and long-lasting option for adding value and beauty to both indoor and outdoor spaces",
	},

	{
		id: uuidv4(),
		img: gardenBed1,
		heading: "Garden Beds",
		imgPopup: gardenBed1,
		category: "garden",
		description:
			"Our garden bed construction service is designed to provide you with a beautiful and functional garden space that enhances the beauty and value of your property. We offer a range of materials and designs to match your style and needs, including raised garden beds, mulch beds and natural stone edging. With our garden bed construction service, you can enjoy the benefits of fresh produce and beautiful flowers right outside your home",
	},
	{
		id: uuidv4(),
		img: gardenBed2,
		heading: "Garden Beds",
		imgPopup: gardenBed2,
		category: "garden",
		description:
			"Our garden bed construction service is designed to provide you with a beautiful and functional garden space that enhances the beauty and value of your property. We offer a range of materials and designs to match your style and needs, including raised garden beds, mulch beds and natural stone edging. With our garden bed construction service, you can enjoy the benefits of fresh produce and beautiful flowers right outside your home",
	},
	{
		id: uuidv4(),
		img: gardenBed3,
		heading: "Garden Beds",
		imgPopup: gardenBed3,
		category: "garden",
		description:
			"Our garden bed construction service is designed to provide you with a beautiful and functional garden space that enhances the beauty and value of your property. We offer a range of materials and designs to match your style and needs, including raised garden beds, mulch beds and natural stone edging. With our garden bed construction service, you can enjoy the benefits of fresh produce and beautiful flowers right outside your home",
	},
	{
		id: uuidv4(),
		img: gardenBed4,
		heading: "Garden Beds",
		imgPopup: gardenBed4,
		category: "garden",
		description:
			"Our garden bed construction service is designed to provide you with a beautiful and functional garden space that enhances the beauty and value of your property. We offer a range of materials and designs to match your style and needs, including raised garden beds, mulch beds and natural stone edging. With our garden bed construction service, you can enjoy the benefits of fresh produce and beautiful flowers right outside your home",
	},
	{
		id: uuidv4(),
		img: gardenBed5,
		heading: "Garden Beds",
		imgPopup: gardenBed5,
		category: "garden",
		description:
			"Our garden bed construction service is designed to provide you with a beautiful and functional garden space that enhances the beauty and value of your property. We offer a range of materials and designs to match your style and needs, including raised garden beds, mulch beds and natural stone edging. With our garden bed construction service, you can enjoy the benefits of fresh produce and beautiful flowers right outside your home",
	},
	{
		id: uuidv4(),
		img: lawncare1,
		heading: "Lawn Care",
		imgPopup: lawncare1,
		category: "lawn",
		description:
			"Our lawn care service is designed to keep your lawn healthy, green, and lush all year round. We provide a range of services, including mowing, trimming, edging, fertilization and weed control. Our team of experienced professionals uses the latest techniques and equipment to ensure that your lawn is always looking its best",
	},
	{
		id: uuidv4(),
		img: lawncare2,
		heading: "Lawn Care",
		imgPopup: lawncare2,
		category: "lawn",
		description:
			"Our lawn care service is designed to keep your lawn healthy, green, and lush all year round. We provide a range of services, including mowing, trimming, edging, fertilization and weed control. Our team of experienced professionals uses the latest techniques and equipment to ensure that your lawn is always looking its best",
	},

	{
		id: uuidv4(),
		img: lawncare3,
		heading: "Lawn Care",
		imgPopup: lawncare3,
		category: "lawn",
		description:
			"Our lawn care service is designed to keep your lawn healthy, green, and lush all year round. We provide a range of services, including mowing, trimming, edging, fertilization and weed control. Our team of experienced professionals uses the latest techniques and equipment to ensure that your lawn is always looking its best",
	},
	{
		id: uuidv4(),
		img: lawncare4,
		heading: "Lawn Care",
		imgPopup: lawncare4,
		category: "lawn",
		description:
			"Our lawn care service is designed to keep your lawn healthy, green, and lush all year round. We provide a range of services, including mowing, trimming, edging, fertilization and weed control. Our team of experienced professionals uses the latest techniques and equipment to ensure that your lawn is always looking its best",
	},
	{
		id: uuidv4(),
		img: lawncare5,
		heading: "Lawn Care",
		imgPopup: lawncare5,
		category: "lawn",
		description:
			"Our lawn care service is designed to keep your lawn healthy, green, and lush all year round. We provide a range of services, including mowing, trimming, edging, fertilization and weed control. Our team of experienced professionals uses the latest techniques and equipment to ensure that your lawn is always looking its best",
	},
	{
		id: uuidv4(),
		img: lawncare6,
		heading: "Lawn Care",
		imgPopup: lawncare6,
		category: "lawn",
		description:
			"Our lawn care service is designed to keep your lawn healthy, green, and lush all year round. We provide a range of services, including mowing, trimming, edging, fertilization and weed control. Our team of experienced professionals uses the latest techniques and equipment to ensure that your lawn is always looking its best",
	},
	{
		id: uuidv4(),
		img: lawncare7,
		heading: "Lawn Care",
		imgPopup: lawncare7,
		category: "lawn",
		description:
			"Our lawn care service is designed to keep your lawn healthy, green, and lush all year round. We provide a range of services, including mowing, trimming, edging, fertilization and weed control. Our team of experienced professionals uses the latest techniques and equipment to ensure that your lawn is always looking its best",
	},
	{
		id: uuidv4(),
		img: lawncare9,
		heading: "Lawn Care",
		imgPopup: lawncare9,
		category: "lawn",
		description:
			"Our lawn care service is designed to keep your lawn healthy, green, and lush all year round. We provide a range of services, including mowing, trimming, edging, fertilization and weed control. Our team of experienced professionals uses the latest techniques and equipment to ensure that your lawn is always looking its best",
	},
	{
		id: uuidv4(),
		img: lawncare10,
		heading: "Lawn Care",
		imgPopup: lawncare10,
		category: "lawn",
		description:
			"Our lawn care service is designed to keep your lawn healthy, green, and lush all year round. We provide a range of services, including mowing, trimming, edging, fertilization and weed control. Our team of experienced professionals uses the latest techniques and equipment to ensure that your lawn is always looking its best",
	},
	{
		id: uuidv4(),
		img: lawncare11,
		heading: "Lawn Care",
		imgPopup: lawncare11,
		category: "lawn",
		description:
			"Our lawn care service is designed to keep your lawn healthy, green, and lush all year round. We provide a range of services, including mowing, trimming, edging, fertilization and weed control. Our team of experienced professionals uses the latest techniques and equipment to ensure that your lawn is always looking its best",
	},
	{
		id: uuidv4(),
		img: lawncare12,
		heading: "Lawn Care",
		imgPopup: lawncare12,
		category: "lawn",
		description:
			"Our lawn care service is designed to keep your lawn healthy, green, and lush all year round. We provide a range of services, including mowing, trimming, edging, fertilization and weed control. Our team of experienced professionals uses the latest techniques and equipment to ensure that your lawn is always looking its best",
	},
	{
		id: uuidv4(),
		img: fence1,
		heading: "Fence Construction",
		imgPopup: fence1,
		category: "fence",
		description:
			"Our fence construction service provides top-quality fence installation and repair services to homeowners and businesses. We specialize in building durable and attractive fences using the highest quality materials. Our team of experienced professionals is equipped with the tools and expertise necessary to complete any fencing project, whether it's a simple repair or a complete installation.",
	},
	{
		id: uuidv4(),
		img: fence2,
		heading: "Fence Construction",
		imgPopup: fence2,
		category: "fence",
		description:
			"Our fence construction service provides top-quality fence installation and repair services to homeowners and businesses. We specialize in building durable and attractive fences using the highest quality materials. Our team of experienced professionals is equipped with the tools and expertise necessary to complete any fencing project, whether it's a simple repair or a complete installation.",
	},

	{
		id: uuidv4(),
		img: fence3,
		heading: "Fence Construction",
		imgPopup: fence3,
		category: "fence",
		description:
			"Our fence construction service provides top-quality fence installation and repair services to homeowners and businesses. We specialize in building durable and attractive fences using the highest quality materials. Our team of experienced professionals is equipped with the tools and expertise necessary to complete any fencing project, whether it's a simple repair or a complete installation.",
	},

	{
		id: uuidv4(),
		img: drainage1,
		heading: "Drainage Solutions",
		imgPopup: drainage1,
		category: "drain",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
	},

	{
		id: uuidv4(),
		img: drainage2,
		heading: "Drainage Solutions",
		imgPopup: drainage2,
		category: "drain",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
	},
	{
		id: uuidv4(),
		img: drainage3,
		heading: "Drainage Solutions",
		imgPopup: drainage3,
		category: "drain",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
	},
	{
		id: uuidv4(),
		img: drainage4,
		heading: "Drainage Solutions",
		imgPopup: drainage4,
		category: "drain",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
	},
	{
		id: uuidv4(),
		img: drainage5,
		heading: "Drainage Solutions",
		imgPopup: drainage5,
		category: "drain",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
	},
	{
		id: uuidv4(),
		img: drainage6,
		heading: "Drainage Solutions",
		imgPopup: drainage6,
		category: "drain",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
	},
	{
		id: uuidv4(),
		img: drainage7,
		heading: "Drainage Solutions",
		imgPopup: drainage7,
		category: "drain",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
	},
	{
		id: uuidv4(),
		img: drainage8,
		heading: "Drainage Solutions",
		imgPopup: drainage8,
		category: "drain",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
	},
	{
		id: uuidv4(),
		img: drainage9,
		heading: "Drainage Solutions",
		imgPopup: drainage9,
		category: "drain",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
	},
	{
		id: uuidv4(),
		img: drainage10,
		heading: "Drainage Solutions",
		imgPopup: drainage10,
		category: "drain",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
	},
	{
		id: uuidv4(),
		img: drainage11,
		heading: "Drainage Solutions",
		imgPopup: drainage11,
		category: "drain",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
	},
	{
		id: uuidv4(),
		img: drainage12,
		heading: "Drainage Solutions",
		imgPopup: drainage12,
		category: "drain",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
	},
	{
		id: uuidv4(),
		img: drainage13,
		heading: "Drainage Solutions",
		imgPopup: drainage13,
		category: "drain",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
	},
	{
		id: uuidv4(),
		img: drainage14,
		heading: "Drainage Solutions",
		imgPopup: drainage14,
		category: "drain",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
	},
	{
		id: uuidv4(),
		img: drainage15,
		heading: "Drainage Solutions",
		imgPopup: drainage15,
		category: "drain",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
	},
	{
		id: uuidv4(),
		img: drain_stone_retain,
		heading: "Drainage Solutions",
		imgPopup: drain_stone_retain,
		category: "drain",
		description:
			"Drainage solutions are designed to manage excess water on your property, preventing flooding, erosion, and water damage. We offer a range of drainage solutions, including French drains and catch basins, to channel water away from your property and home. With our drainage solutions, you can protect your property from water damage and have peace of mind during heavy rain or storms",
	},

	{
		id: uuidv4(),
		img: painting1,
		heading: "Painting",
		imgPopup: painting1,
		category: "paint",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
	},
	{
		id: uuidv4(),
		img: painting2,
		heading: "Painting",
		imgPopup: painting2,
		category: "paint",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
	},
	{
		id: uuidv4(),
		img: painting3,
		heading: "Painting",
		imgPopup: painting3,
		category: "paint",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
	},
	{
		id: uuidv4(),
		img: painting4,
		heading: "Painting",
		imgPopup: painting4,
		category: "paint",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
	},
	{
		id: uuidv4(),
		img: painting5,
		heading: "Painting",
		imgPopup: painting5,
		category: "paint",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
	},
	{
		id: uuidv4(),
		img: painting6,
		heading: "Painting",
		imgPopup: painting6,
		category: "paint",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
	},
	{
		id: uuidv4(),
		img: painting7,
		heading: "Painting",
		imgPopup: painting7,
		category: "paint",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
	},
	{
		id: uuidv4(),
		img: painting8,
		heading: "Painting",
		imgPopup: painting8,
		category: "paint",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
	},
	{
		id: uuidv4(),
		img: painting9,
		heading: "Painting",
		imgPopup: painting9,
		category: "paint",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
	},
	{
		id: uuidv4(),
		img: painting10,
		heading: "Painting",
		imgPopup: painting10,
		category: "paint",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
	},
	{
		id: uuidv4(),
		img: painting11,
		heading: "Painting",
		imgPopup: painting11,
		category: "paint",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
	},
	{
		id: uuidv4(),
		img: painting12,
		heading: "Painting",
		imgPopup: painting12,
		category: "paint",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
	},
	{
		id: uuidv4(),
		img: painting13,
		heading: "Painting",
		imgPopup: painting13,
		category: "paint",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
	},
	{
		id: uuidv4(),
		img: painting14,
		heading: "Painting",
		imgPopup: painting14,
		category: "paint",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
	},
	{
		id: uuidv4(),
		img: painting15,
		heading: "Painting",
		imgPopup: painting15,
		category: "paint",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
	},
	{
		id: uuidv4(),
		img: painting16,
		heading: "Painting",
		imgPopup: painting16,
		category: "paint",
		description:
			"Our painting service offers high-quality interior and exterior painting for both residential and commercial properties. We are dedicated to delivering a beautiful and long-lasting finish that meets your needs and transforms your space",
	},
	{
		id: uuidv4(),
		img: mulch1,
		heading: "Mulching",
		imgPopup: mulch1,
		category: "mulch",
		description:
			"Our mulching service provides a cost-effective and eco-friendly way to improve the appearance and health of your garden or landscape. Our team of skilled professionals will assess your garden or landscape to determine the appropriate amount of mulch required and select the best type of mulch for your specific needs. ",
	},
	{
		id: uuidv4(),
		img: mulch2,
		heading: "Mulching",
		imgPopup: mulch2,
		category: "mulch",
		description:
			"Our mulching service provides a cost-effective and eco-friendly way to improve the appearance and health of your garden or landscape. Our team of skilled professionals will assess your garden or landscape to determine the appropriate amount of mulch required and select the best type of mulch for your specific needs. ",
	},
	{
		id: uuidv4(),
		img: mulch3,
		heading: "Mulching",
		imgPopup: mulch3,
		category: "mulch",
		description:
			"Our mulching service provides a cost-effective and eco-friendly way to improve the appearance and health of your garden or landscape. Our team of skilled professionals will assess your garden or landscape to determine the appropriate amount of mulch required and select the best type of mulch for your specific needs. ",
	},
	{
		id: uuidv4(),
		img: mulch4,
		heading: "Mulching",
		imgPopup: mulch4,
		category: "mulch",
		description:
			"Our mulching service provides a cost-effective and eco-friendly way to improve the appearance and health of your garden or landscape. Our team of skilled professionals will assess your garden or landscape to determine the appropriate amount of mulch required and select the best type of mulch for your specific needs. ",
	},
	{
		id: uuidv4(),
		img: mulch5,
		heading: "Mulching",
		imgPopup: mulch5,
		category: "mulch",
		description:
			"Our mulching service provides a cost-effective and eco-friendly way to improve the appearance and health of your garden or landscape. Our team of skilled professionals will assess your garden or landscape to determine the appropriate amount of mulch required and select the best type of mulch for your specific needs. ",
	},

	{
		id: uuidv4(),
		img: pressure1,
		heading: "Pressure Washing",
		imgPopup: pressure1,
		category: "pressure",
		description:
			"Our pressure washing services combine high-pressure water and cleaning solutions to remove dirt, grime, mold, and other stains from a variety of surfaces, including siding, decks, patios, sidewalks, and driveways. Pressure washing your property’s surfaces will leave it looking brand new",
	},
	{
		id: uuidv4(),
		img: pressure2,
		heading: "Pressure Washing",
		imgPopup: pressure2,
		category: "pressure",
		description:
			"Our pressure washing services combine high-pressure water and cleaning solutions to remove dirt, grime, mold, and other stains from a variety of surfaces, including siding, decks, patios, sidewalks, and driveways. Pressure washing your property’s surfaces will leave it looking brand new",
	},
	{
		id: uuidv4(),
		img: trim1,
		heading: "Tree Trimming",
		imgPopup: trim1,
		category: "trim",
		description:
			"Our tree trimming service provides professional and efficient trimming services for residential and commercial properties. Our skilled arborists use the latest techniques and equipment to trim your trees to promote healthy growth and maintain a beautiful appearance. We take great care to ensure the safety of your property and surrounding landscape during the trimming process",
	},
	{
		id: uuidv4(),
		img: trim2,
		heading: "Tree Trimming",
		imgPopup: trim2,
		category: "trim",
		description:
			"Our tree trimming service provides professional and efficient trimming services for residential and commercial properties. Our skilled arborists use the latest techniques and equipment to trim your trees to promote healthy growth and maintain a beautiful appearance. We take great care to ensure the safety of your property and surrounding landscape during the trimming process",
	},
	{
		id: uuidv4(),
		img: trim3,
		heading: "Tree Trimming",
		imgPopup: trim3,
		category: "trim",
		description:
			"Our tree trimming service provides professional and efficient trimming services for residential and commercial properties. Our skilled arborists use the latest techniques and equipment to trim your trees to promote healthy growth and maintain a beautiful appearance. We take great care to ensure the safety of your property and surrounding landscape during the trimming process",
	},
	{
		id: uuidv4(),
		img: trim4,
		heading: "Tree Trimming",
		imgPopup: trim4,
		category: "trim",
		description:
			"Our tree trimming service provides professional and efficient trimming services for residential and commercial properties. Our skilled arborists use the latest techniques and equipment to trim your trees to promote healthy growth and maintain a beautiful appearance. We take great care to ensure the safety of your property and surrounding landscape during the trimming process",
	},
	{
		id: uuidv4(),
		img: trim5,
		heading: "Tree Trimming",
		imgPopup: trim5,
		category: "trim",
		description:
			"Our tree trimming service provides professional and efficient trimming services for residential and commercial properties. Our skilled arborists use the latest techniques and equipment to trim your trees to promote healthy growth and maintain a beautiful appearance. We take great care to ensure the safety of your property and surrounding landscape during the trimming process",
	},
	{
		id: uuidv4(),
		img: trim6,
		heading: "Tree Trimming",
		imgPopup: trim6,
		category: "trim",
		description:
			"Our tree trimming service provides professional and efficient trimming services for residential and commercial properties. Our skilled arborists use the latest techniques and equipment to trim your trees to promote healthy growth and maintain a beautiful appearance. We take great care to ensure the safety of your property and surrounding landscape during the trimming process",
	},
	{
		id: uuidv4(),
		img: trim7,
		heading: "Tree Trimming",
		imgPopup: trim7,
		category: "trim",
		description:
			"Our tree trimming service provides professional and efficient trimming services for residential and commercial properties. Our skilled arborists use the latest techniques and equipment to trim your trees to promote healthy growth and maintain a beautiful appearance. We take great care to ensure the safety of your property and surrounding landscape during the trimming process",
	},
	{
		id: uuidv4(),
		img: trimTest,
		heading: "Tree Trimming",
		imgPopup: trimTest,
		category: "trim",
		description:
			"Our tree trimming service provides professional and efficient trimming services for residential and commercial properties. Our skilled arborists use the latest techniques and equipment to trim your trees to promote healthy growth and maintain a beautiful appearance. We take great care to ensure the safety of your property and surrounding landscape during the trimming process",
	},
];

const ProjectStyleFourItem = ({ img, imgPopup, heading }) => {
	const [open, setOpen] = React.useState(false);
	// const [index, setIndex] = React.useState(0);

	const { projectStyleFourItem } = useContext(appContext);

	let index = projectStyleFourItem.findIndex((item) => item.img === img);
	const slides = projectStyleFourItem.map((item) => ({
		src: item.imgPopup,
	}));

	return (
		<>
			<div className="col-xl-3 col-lg-3 col-md-4 planting">
				<Bounce bottom>
					<div
						className="single-project-isotope-style-2 bg-property mb-25"
						style={{ backgroundImage: `url(${img})` }}
						onClick={() => {
							setOpen(true);
						}}
					>
						<div className="portfolio-content-2">
							<button type="button" className="popup-image">
								<FaSearchPlus />
							</button>
							<Lightbox
								open={open}
								close={() => {
									setOpen(false);
								}}
								slides={slides}
								index={index}
							/>
							{/* <Link to={btnLink}>
								<FaLink />
							</Link> */}
						</div>
						<div className="portfolio-heading-content-2">
							<h3>{heading}</h3>
						</div>
					</div>
				</Bounce>
			</div>
		</>
	);
};

const ProjectStyleFour = () => {
	// STATES
	// const [category, setCategory] = useState("all");
	// const [projectStyleFourItem, setProjectStyleFourItem] = useState([]);

	const {
		projectStyle,
		setProjectStyle,
		setProjectStyleFourItem,
		projectStyleFourItem,
	} = useContext(appContext);

	return (
		<>
			<div className="project-area pt-110 pb-110">
				<div className="container">
					<div className="row">
						<div className="col-xl-12">
							<div className="section-title text-center mb-70">
								<span>Our Projects</span>
								<h2>{projectStyleFourItem[0]?.heading}</h2>
								<p>{projectStyleFourItem[0]?.description}</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 text-center">
							<button
								className={`btn btn-dark portfolio-menu mb-60 border-0 rounded-0 px-5 ${
									projectStyle === "all" ? "active" : ""
								}`}
								onClick={() => setProjectStyle("all")}
							>
								Back to Services
							</button>
						</div>
					</div>
					<div
						className="row portfolio-active"
						style={{ width: "100%" }}
					>
						{projectStyleFourItem.map((item, length) => (
							<ProjectStyleFourItem
								key={item.id}
								img={item.img}
								imgPopup={item.imgPopup}
								heading={item.heading}
								category={item.category}
								btnLink={item.btnLink}
							/>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default ProjectStyleFour;
