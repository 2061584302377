import React from "react";
import HomeTwoFeature1 from "../../assets/images/icon/satisfaction-guarantee.png";
import HomeTwoFeature2 from "../../assets/images/icon/professional-team.png";
import HomeTwoFeature3 from "../../assets/images/icon/material-instrument.png";
import HomeTwoFeature4 from "../../assets/images/icon/experience.png";

const HomeTwoFeature = () => {
	return (
		<>
			<div className="features-area white-bg pt-110 pb-110">
				<div className="container">
					<div className="row">
						<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
							<div className="single-feature text-center box-shadow-3">
								<div className="single-feature-icon">
									<img src={HomeTwoFeature1} alt="" />
								</div>
								<h5 style={{ marginBottom: 50 }}>
									Trusted Service Provider
								</h5>
								<p>
									Our dedication to providing top-notch
									service has earned us the trust of over 20
									regular clients in the Asheville area, and
									we strive to exceed their expectations with
									every job we do.
								</p>
							</div>
						</div>
						<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
							<div className="single-feature text-center box-shadow-3">
								<div className="single-feature-icon">
									<img src={HomeTwoFeature2} alt="" />
								</div>
								<h5>
									Extensive Property Management Experience
								</h5>
								<p>
									With a proven track record of successfully
									managing properties as large as 256 acres,
									our team has the expertise to handle all
									aspects of property management.
								</p>
							</div>
						</div>
						<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
							<div className="single-feature text-center box-shadow-3">
								<div className="single-feature-icon">
									<img src={HomeTwoFeature3} alt="" />
								</div>
								<h5 style={{ marginBottom: 45 }}>
									Project Experience
								</h5>
								<p>
									With a portfolio of successfully completed
									projects in the hundreds, we have the
									experience and knowledge to handle any
									project in the greater Asheville area.
								</p>
							</div>
						</div>
						<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
							<div className="single-feature text-center box-shadow-3">
								<div className="single-feature-icon">
									<img src={HomeTwoFeature4} alt="" />
								</div>
								<h5 style={{ marginBottom: 42 }}>
									Top Notch Professionalism
								</h5>
								<p>
									Our team of skilled craftsmen have honed
									their craft for over 10 years, and are
									experts in stonework, fencing, painting, and
									designing distinct and individualistic
									landscapes that will leave a lasting
									impression.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default HomeTwoFeature;
