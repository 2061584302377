import React from "react";
import { FaPhoneAlt, FaClock, FaGoogle } from "react-icons/fa";

const HeaderTop = () => {
	return (
		<div className="header-top pt-15 pb-15 theme-bg-1">
			<div className="container">
				<div className="row">
					<div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
						<div className="header-info">
							<ul>
								<li>
									<FaPhoneAlt />
									{/* Call at : */}
									<a href="/"> (828) 335-2141</a>
								</li>
								<li>
									<FaClock /> Open Hours : Mon-Sat 8:00 AM -
									6:00 PM
								</li>
							</ul>
						</div>
					</div>
					<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
						<div className="header-social text-center text-xl-end text-lg-end">
							{/* <a href="facebook.com">Facebook</a> */}
							<a
								href="https://www.google.com/search?q=ramos+landscaping+asheville+nc&oq=ramos+landsca&aqs=chrome.1.69i57j69i59l3j69i60l3.3101j0j1&sourceid=chrome&ie=UTF-8"
								target="_blank"
							>
								Google
							</a>
							{/* <a href="facebook.com">Twitter</a>
							<a href="instagram.com">Instagram</a> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeaderTop;
