import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { appContext } from "../../context/context";

const Nav = () => {
	const { setProjectStyle } = useContext(appContext);
	return (
		<div className="main-menu text-center">
			<nav id="mobile-menu">
				<ul>
					<li>
						<Link to="/">Home</Link>
					</li>
					{/* <li>
						<Link to="/services">Services</Link>
					</li> */}
					<li>
						<Link
							to="/services"
							onClick={() => {
								setProjectStyle("all");
							}}
						>
							Services
						</Link>
					</li>

					<li>
						<Link to="/about">About</Link>
					</li>
					<li>
						<Link to="/contact">Contact</Link>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default Nav;
