import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import AboutBlockImg1 from "../../assets/images/about/about-large.jpg";
import AboutBlockImg2 from "../../assets/images/about/about-small.jpg";
import AboutBlockImg3 from "../../assets/images/about/about-author-avatar.jpg";
import headshot from "../../assets/images/about/headshot.jpeg";

const AboutExperienceData = [
	{
		id: uuidv4(),
		heading: "Mission",
		description:
			"To collaborate with homeowners in transforming their outdoor spaces into attractive and functional parts of their homes by creating landscapes that connect our community with the natural beauty of the Blue Ridge mountains.",
	},

	{
		id: uuidv4(),
		heading: "Vision",
		description:
			"To be the leading landscaping company in our community known for constructing unique landscapes that bring joy to its customers’ lives.",
	},

	{
		id: uuidv4(),
		heading: "Goal",
		description:
			"To consistently provide high-quality service that exceeds our clients’ expectations while also cultivating a team of experts to help drive our company.",
	},
];

const AboutExperienceItem = ({ heading, description, img }) => {
	return (
		<>
			<div className="single-experience">
				<h4>{heading}</h4>
				<p>{description}</p>
			</div>
		</>
	);
};

const AboutBlock = () => {
	return (
		<>
			<div className="about-area white-bg pt-110 pb-110">
				<div className="container-fluid">
					<div className="row d-flex align-items-center d-flex justify-content-around">
						<div className="col-xl-4 col-lg-6 col-md-6">
							{AboutExperienceData.map((data) => (
								<AboutExperienceItem
									key={data.id}
									heading={data.heading}
									description={data.description}
								/>
							))}
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6">
							<div className="about-text-wrapper">
								<div className="section-title mb-40">
									{/* <span>About Landi</span> */}
									<h2 className="mb-30">
										Meet Alvaro "Al" Ramos <br />
									</h2>
									<h4>
										The Creative Force Behind Ramos
										Landscaping LLC
									</h4>
									<p>
										Al is an Asheville local with over a
										decade of experience in stonework,
										fencing, painting, and designing
										beautiful and unique landscapes.
										Inspired by his father's legacy of
										preserving the natural beauty of their
										hometown, he continues that tradition by
										encouraging the community to embrace the
										enchanting allure of the Blue Ridge
										mountains. With a commitment to creating
										individualistic and distinct outdoor
										spaces for each of his clients, Al has
										built a reputation for himself as a
										skilled artisan in the landscaping
										industry. His goal is to create an oasis
										for people to escape the hectic pace of
										modern life and relax in their own
										backyard.
									</p>
								</div>
								<div className="row">
									{/* <div className="col-xl-5 col-lg-12">
										<Link to="/" className="l-btn">
											Read More
										</Link>
									</div> */}
									<div className="col-md-11 ">
										<div className="about-author-avatar-wrapper d-flex justify-content-around">
											<div>
												<img src={headshot} alt="" />
												<div className="about-author-avatar-content">
													<h5>Alvaro "Al" Ramos</h5>
													<span>Founder & CEO</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AboutBlock;
