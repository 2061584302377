import React from "react";
import { v4 as uuidv4 } from "uuid";
import Icon_Estimates from "../../assets/images/why-choose-us/estimates.png";
import Icon_Equipment from "../../assets/images/why-choose-us/equipment.png";
import Icon_Work from "../../assets/images/why-choose-us/work.png";
import Icon_Irreigation from "../../assets/images/why-choose-us/irreigation.png";

const WhyChooseUsTwoData = [
	{
		id: uuidv4(),
		img: Icon_Estimates,
		heading: "Estimates",
		description: `Our team will work closely with you to understand your needs and provide an accurate and detailed estimate that reflects the scope of the work to be done. Whether you're looking for a minor repair or a major renovation, you can count on us to deliver high-quality workmanship and exceptional service, from start to finish`,
		imgAlt: "Free Estimates Icon",
	},
	{
		id: uuidv4(),
		img: Icon_Equipment,
		heading: "Combined 60 Years of Experience",
		description:
			"Our team brings together over 60 years of collective experience in the industry. With this wealth of knowledge and expertise, we can confidently handle any project that comes our way. From troubleshooting to innovation, we've seen it all and know how to get the job done right.",
		imgAlt: "Modern Equipment Icon",
	},

	{
		id: uuidv4(),
		heading: "Quality Work",
		description:
			"At our company, quality work is not just a buzzword - it's a core value that we live by. We take pride in delivering work that exceeds our customers' expectations, from the smallest details to the big picture. Our team is dedicated to providing exceptional craftsmanship and attention to detail in every project we undertake.",
		img: Icon_Work,
	},

	{
		id: uuidv4(),
		img: Icon_Irreigation,
		heading: "Customization Options",
		description:
			"We understand that every customer is unique and has different needs and preferences. That's why we offer a range of customization options to help you achieve the perfect look and feel for your project. From materials and colors to finishes and styles, we'll work with you to create a personalized solution that meets your exact specifications.",
		imgAlt: "Customization Options Icon",
	},

	// {
	// 	id: uuidv4(),
	// 	img: Icon_Irreigation,
	// 	heading: "Watering & Irrigation",
	// 	description:
	// 		"There are many variations of passages the majority have suffered alteration form",
	// },
];

const WhyChooseUsTwoItem = ({ heading, description, img, imgAlt }) => {
	return (
		<>
			<div className="col xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
				<div className="single-choose-us">
					<div className="single-choose-us-content bb-1 b-color-2">
						<h3>{heading}</h3>
						<p>{description}</p>
					</div>
					<div className="single-choose-us-icon">
						<img src={img} alt={imgAlt} />
					</div>
				</div>
			</div>
		</>
	);
};

const WhyChoseUsTwo = () => {
	return (
		<>
			<div className="why-choose-us-area white-bg pt-110 pb-110">
				<div className="container">
					<div className="row position-relative">
						<div className="col-xl-12">
							<div className="section-title text-center mb-70">
								<span>Why Choose US</span>
								<h2>
									Experience the Highest <br /> Level of
									Landscaping
								</h2>
							</div>
						</div>
					</div>
					<div className="row position-relative d-flex justify-content-around mt-40">
						{WhyChooseUsTwoData.map((data) => (
							<WhyChooseUsTwoItem
								key={data.id}
								heading={data.heading}
								description={data.description}
								img={data.img}
							/>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default WhyChoseUsTwo;
