import React, { useState } from "react";

export const appContext = React.createContext(null);

export default function AppProvider({ children }) {
	// const [state, setState] = useState({
	// 	projectStyle: "all",
	// 	projectStyleFourItem: [],
	// });

	const [projectStyle, setProjectStyle] = useState("all");
	const [projectStyleFourItem, setProjectStyleFourItem] = useState([]);

	const state = {
		projectStyle,
		setProjectStyle,
		projectStyleFourItem,
		setProjectStyleFourItem,
	};

	return <appContext.Provider value={state}>{children}</appContext.Provider>;
}
