import React from "react";
import HeaderTop from "../common/header/HeaderTop";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import breadcrumbBg from "../assets/images/breadcrumb/DSC04013.JPG";
import FooterOne from "../common/footer/FooterOne";
// import AboutVideoBlock from "../components/about/AboutVideoBlock";
// import FunFact from "../components/fun-fact/FunFact";

import AboutBlock from "../components/about/AboutBlock";
// import CtaTwo from "../components/cta/CtaTwo";

// import Subscribe from "../components/subscribe/Subscribe";

// import HowItWorkOne from "../components/how-it-work/HowItWorkOne";

const About = () => {
	return (
		<>
			<HeaderTop />
			<HeaderBottomOne />
			<Breadcrumb
				breadcrumbBg={breadcrumbBg}
				heading="Our Company"
				// description="We are available for 24/7 for Garden & Landscaping Services"
				currentPage="About Us"
			/>
			{/* <AboutVideoBlock /> */}
			{/* <FunFact /> */}
			{/* <AboutExperience /> */}
			<AboutBlock />
			{/* <CtaTwo /> */}
			{/* <AboutTeam /> */}
			{/* <HowItWorkOne /> */}
			{/* <TestimonialStyleTwo /> */}
			{/* <Subscribe /> */}
			<FooterOne />
		</>
	);
};

export default About;
