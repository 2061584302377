import React from "react";
import HeaderTop from "../common/header/HeaderTop";
import HeaderBottomOne from "../common/header/HeaderBottomOne";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import breadcrumbBg from "../assets/images/breadcrumb/DSC04015.JPG";
import ContactForm from "../components/contact/ContactForm";

import FooterOne from "../common/footer/FooterOne";

const Contact = () => {
	return (
		<>
			<HeaderTop />
			<HeaderBottomOne />
			<Breadcrumb
				breadcrumbBg={breadcrumbBg}
				heading="Contact Us"
				// description="We are available 24/7 for Garden & Landscaping Services"
				currentPage="Contact Us"
			/>
			<ContactForm />
			{/* <TestimonialStyleThree />
			<ContactMap /> */}
			<FooterOne />
		</>
	);
};

export default Contact;
