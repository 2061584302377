import React from "react";
import HeaderTop from "../common/header/HeaderTop";
import HeaderBottomTwo from "../common/header/HeaderBottomTwo";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import breadcrumbBg from "../assets/images/breadcrumb/DSC04015.JPG";
import ErrorContent from "../components/error/ErrorContent";
import FooterTwo from "../common/footer/FooterTwo";

const Error = () => {
	return (
		<>
			<HeaderTop />
			<HeaderBottomTwo />
			<Breadcrumb
				breadcrumbBg={breadcrumbBg}
				// heading="404"
				description="There seems to be a problem with the page you are looking for."
				currentPage="404"
			/>
			<ErrorContent />
			<FooterTwo />
		</>
	);
};

export default Error;
