import React, { useContext } from "react";
import { Link } from "react-router-dom";
import FooterBG from "../../assets/images/footer-1.png";
import {
	FaPhoneAlt,
	FaMapMarkerAlt,
	FaFacebookF,
	FaTwitter,
	FaInstagram,
	FaPinterestP,
	FaAngleDoubleRight,
} from "react-icons/fa";

import { appContext } from "../../context/context";

import FullLogo from "../../assets/images/ramos-llc-logos/PNG/Ramos Landscaping LLC white border-01.png";

const FooterOne = () => {
	const getYear = () => {
		const date = new Date();
		const year = date.getFullYear();
		return year;
	};
	const { setProjectStyle } = useContext(appContext);

	return (
		<>
			<footer>
				<div
					className="footer-top bg-property theme-bg-2 pt-110 pb-80"
					style={{ backgroundImage: `url(${FooterBG})` }}
				>
					<div className="row d-flex justify-content-around">
						<div className="col-xl-2 col-lg-4 col-md-4">
							<div class="footer-logo">
								<img src={FullLogo}></img>
							</div>
						</div>

						<div className="col-md-2 col-sm-4">
							<div className="footer-widget-3">
								<div className="footer-title">
									<h4>Our Services</h4>
								</div>
								<div className="footer-menu">
									<ul>
										<li>
											<Link
												to="/services"
												onClick={() =>
													setProjectStyle("landscape")
												}
											>
												Landscape Design
											</Link>
										</li>
										<li>
											<Link
												to="/services"
												onClick={() =>
													setProjectStyle("lawn")
												}
											>
												Lawn Care
											</Link>
										</li>
										<li>
											<Link
												to="/services"
												onClick={() =>
													setProjectStyle("retaining")
												}
											>
												Retaining Wall Construction
											</Link>
										</li>
										<li>
											<Link
												to="/services"
												onClick={() =>
													setProjectStyle("drain")
												}
											>
												Drainage Solutions
											</Link>
										</li>
										<li>
											<Link
												to="/services"
												onClick={() =>
													setProjectStyle("garden")
												}
											>
												Garden Bed Construction
											</Link>
										</li>
										<li>
											<Link
												to="/services"
												onClick={() =>
													setProjectStyle("stonework")
												}
											>
												Stonework
											</Link>
										</li>
										<li>
											<Link
												to="/services"
												onClick={() =>
													setProjectStyle("fence")
												}
											>
												Fence Construction
											</Link>
										</li>
										<li>
											<Link
												to="/services"
												onClick={() =>
													setProjectStyle("paint")
												}
											>
												Painting
											</Link>
										</li>
										<li>
											<Link
												to="/services"
												onClick={() =>
													setProjectStyle("trim")
												}
											>
												Tree Trimming
											</Link>
										</li>
										<li>
											<Link
												to="/services"
												onClick={() =>
													setProjectStyle("pressure")
												}
											>
												Pressure Washing
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-2 col-sm-4">
							<div className="footer-widget-2">
								<div className="footer-title">
									<h4>Quick Links</h4>
								</div>
								<div className="footer-menu">
									<ul>
										<li>
											<Link to="/contact">
												Contact Us
											</Link>
										</li>
										{/* <li>
													<Link to="/">Team</Link>
												</li> */}
										<li>
											<Link
												to="/services"
												onClick={() =>
													setProjectStyle("all")
												}
											>
												Our Services
											</Link>
										</li>
										{/* <li>
											<Link to="/services">
												Photo Gallery
											</Link>
										</li> */}
										<li>
											<Link to="/about">
												About Company
											</Link>
										</li>
										{/* <li>
													<Link to="/">News Update</Link>
												</li> */}
									</ul>
								</div>
							</div>
						</div>

						<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4">
							<div className="footer-widget-1">
								<div className="footer-title">
									<h4>Contact Us</h4>
								</div>
								<div className="footer-text">
									<p>
										If you have any questions or need help,
										feel free to contact with our team.
									</p>
								</div>
								<div className="footer-info">
									<Link to="/contact">
										<FaPhoneAlt /> (828) 335-2141
									</Link>
								</div>
								{/* <div className="footer-text">
									<p>45 East St, Asheville, NC 28803</p>
								</div>
								<div className="footer-info">
									<a
										href="https://www.google.com/maps/dir//45+East+St,+Asheville,+NC+28803/@36.6361844,-87.4504878,15z/data=!4m2!4m1!3e0"
										target="_blank"
									>
										<FaMapMarkerAlt /> Get Directions
									</a>
								</div> */}
							</div>
						</div>
						{/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
									<div className="footer-widget-4">
										<div className="footer-title">
											<h4>Newsletter</h4>
										</div>
										<div className="footer-text">
											<p>
												Established since 2000. Mission
												Eland Landscaping Company in US.
											</p>
										</div>
										<div className="footer-social mt-35">
											<h5>Folllow Us:</h5>
											<ul>
												<li>
													<Link to="/">
														<FaFacebookF />
													</Link>
												</li>
												<li>
													<Link to="/">
														<FaTwitter />
													</Link>
												</li>
												<li>
													<Link to="/">
														<FaInstagram />
													</Link>
												</li>
												<li>
													<Link to="/">
														<FaPinterestP />
													</Link>
												</li>
											</ul>
										</div>
									</div>
								</div> */}
					</div>
				</div>

				<div className="footer-bottom theme-bg-2 pt-40 pb-30">
					<div className="container">
						<div className="row d-flex justify-content-around">
							<div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 text-center">
								<div className="copyright">
									<p>
										© {getYear()}{" "}
										<Link to="/">
											Ramos Landscaping LLC
										</Link>
										. All right reserved.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default FooterOne;
