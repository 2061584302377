import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { FaLevelUpAlt } from "react-icons/fa";

// pages
import Home from "./pages/Home";
import ScrollToTopRoute from "./components/scroll-to-top-route/ScrollToTopRoute";
import ServiceOne from "./pages/ServiceOne";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Error from "./pages/Error";
import FooterOne from "./common/footer/FooterOne";

import ContextProvider from "./context/context";

//CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/vendor/slick/slick.css";
import "./assets/css/default.css";
import "./assets/css/main.css";
import "./assets/css/responsive.css";
import "./assets/vendor/modal-video/modal-video.min.css";
import "../node_modules/yet-another-react-lightbox/dist/styles.css";
function App() {
	return (
		<>
			<ContextProvider>
				<Router>
					<ScrollToTopRoute />
					<Routes>
						<Route path="/" element={<Home />} />
						{/* <Route path="services" element={<ServiceOne />} /> */}
						<Route path="contact" exact element={<Contact />} />
						<Route path="about" exact element={<About />} />
						<Route path="services" exact element={<Gallery />} />
						<Route path="*" element={<Error />} />
					</Routes>
					<ScrollToTop
						className="scrollUp"
						smooth
						top="1500"
						component={<FaLevelUpAlt />}
					/>
				</Router>
			</ContextProvider>
		</>
	);
}

export default App;
