import React from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import Slider from "react-slick";
import HeroStyleTwoImg2 from "../../assets/images/hero/DSC04005.JPG";
import HeroStyleTwoImg3 from "../../assets/images/hero/DSC04024.JPG";

const HeroStyleTwoData = [
	{
		id: uuidv4(),
		img: HeroStyleTwoImg2,
		heading: "Welcome To Our Landscaping Design & Solutions",
		description:
			"We offer a collaborative approach to landscape design that allows homeowners to join in on turning an outdoor space into an attractive and functional part of a home. Here at Ramos Landscaping LLC, It brings us so much joy to collaborate with clients' ideas as we create and beautify their own oasis and bring it to life as we put our experience to work!",
		btnLink: "/about",
		btnText: "Our Solution",
	},
	{
		id: uuidv4(),
		img: HeroStyleTwoImg3,
		heading: "Welcome To Our Landscaping Design & Solutions",
		description:
			"Do you have an idea but you’re not sure where to start? Let’s work together in turning an idea into your own oasis! ",
		btnLink: "/about",
		btnText: "Our Solution",
	},
];

const HeroStyleTwoItem = ({ img, heading, description, btnLink, btnText }) => {
	return (
		<>
			<div
				className="single-slider-style-1 height-800 bg-property d-flex align-items-center"
				style={{
					backgroundImage: `url(${img})`,
					backgroundSize: "cover",
				}}
			>
				<div className="container">
					<div className="row">
						<div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 offset-xl-1 offset-lg-1 offset-md-1">
							<div className="slide-content-wrapper-1">
								<h1>{heading}</h1>
								<p>{description}</p>
								<a href={btnLink} className="l-btn">
									{btnText}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const HeroStyleTwo = () => {
	const settings = {
		autoplay: true,
		autoplaySpeed: 10000,
		dots: true,
		fade: true,
		arrows: true,
		prevArrow: (
			<button type="button" className="slick-prev">
				<FaAngleLeft />
			</button>
		),
		nextArrow: (
			<button type="button" className="slick-next">
				<FaAngleRight />
			</button>
		),
		responsive: [
			{ breakpoint: 767, settings: { dots: false, arrows: false } },
		],
	};
	return (
		<>
			<div className="hero-slider-1">
				<Slider {...settings}>
					{HeroStyleTwoData.map((data) => (
						<HeroStyleTwoItem
							key={data.id}
							img={data.img}
							heading={data.heading}
							description={data.description}
							btnLink={data.btnLink}
							btnText={data.btnText}
						/>
					))}
				</Slider>
			</div>
		</>
	);
};

export default HeroStyleTwo;
