import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { Link } from "react-router-dom";
import {
	FaArrowRight,
	FaEnvelopeOpen,
	FaClock,
	FaPhoneAlt,
} from "react-icons/fa";

const ContactForm = () => {
	const [state, handleSubmit] = useForm("mlekwvrk");
	const [checkboxValues, setCheckboxValues] = useState("");
	const saveCheckboxValues = () => {
		const checkboxes = document.querySelectorAll('input[type="checkbox"]');
		let checked = [];
		checkboxes.forEach((checkbox) => {
			if (checkbox.checked) {
				checked.push(checkbox.value);
			}
		});

		const fomrattetdValues = checked
			.map((value) => `- ${value}`)
			.join(`\n`);

		setCheckboxValues(fomrattetdValues);
	};

	return (
		<>
			<div className="contact-area white-bg pt-110 pb-110">
				<div className="container">
					<div className="box-shadow-3 pt-60 pb-60 pl-80 pr-80">
						<div className="row">
							<div className="col-xl-6 col-lg-6">
								<div className="contact-form pr-70">
									<div className="section-title mb-40">
										<span>
											Need Our Service Or Have Questions?
										</span>
										<h2>Send A Message.</h2>
									</div>
									<form
										method="POST"
										onSubmit={() => {
											handleSubmit();
											alert(
												"Your Inquiry Has Been Sent!"
											);
											window.location.reload(true);
										}}
									>
										<input
											type="text"
											placeholder="Name"
											name="Name"
											className="text-field"
										/>
										<ValidationError
											field="Name"
											prefix="Name"
											errors={state.errors}
										/>
										<input
											type="email"
											placeholder="Email"
											name="Email"
											className="text-field"
										/>
										<ValidationError
											field="Email"
											prefix="Email"
											errors={state.errors}
										/>
										<input
											type="tel"
											placeholder="Phone Number"
											name="Phone Number"
											className="text-field"
										/>
										<ValidationError
											field="Phone Number"
											prefix="Phone Number"
											errors={state.errors}
										/>

										<div className="row checkbox-container d-flex flex-column ">
											<div className="col-xl-9 col-lg-12 col-md-8">
												<label>
													What services are you
													interested in?
												</label>
											</div>

											<div className="col-lg-7 col-md-5">
												<input
													className="check"
													type="checkbox"
													value="Landscape Design"
												/>
												<span>Landscape Design</span>
											</div>
											<div className="col-xl-7 col-lg-9 col-md-6">
												<input
													className="check"
													type="checkbox"
													value="Lawn Care"
												/>
												<span>Lawn Care</span>
											</div>
											<div className="col-xl-7 col-lg-9 col-md-6">
												<input
													className="check"
													type="checkbox"
													value="Retaining Wall Construction"
												/>
												<span>
													Retaining Wall Construction
												</span>
											</div>
											<div className="col-lg-7 col-md-5">
												<input
													className="check"
													type="checkbox"
													value="Drainage Solutions"
												/>
												<span>Drainage Solutions</span>
											</div>
											<div className="col-xl-7 col-lg-9 col-md-6">
												<input
													className="check"
													type="checkbox"
													value="Garden Beds"
												/>
												<span>Garden Beds</span>
											</div>
											<div className="col-xl-4 col-lg-5 col-md-3">
												<input
													className="check"
													type="checkbox"
													value="Stonework"
												/>
												<span>Stonework</span>
											</div>
											<div className="col-xl-7 col-lg-9 col-md-6">
												<input
													className="check"
													type="checkbox"
													value="Fence Construction"
												/>
												<span>Fence Construction</span>
											</div>
											<div className="col-lg-8 col-md-6">
												<input
													className="check"
													type="checkbox"
													value="Painting"
												/>
												<span>Painting</span>
											</div>
											<div className="col-xl-5 col-lg-6 col-md-4">
												<input
													className="check"
													type="checkbox"
													value="Tree Trimming"
												/>
												<span>Tree Trimming</span>
											</div>
											<div className="col-xl-5 col-lg-6 col-md-4">
												<input
													className="check"
													type="checkbox"
													value="Pressure Washing"
												/>
												<span>Pressure Washing</span>
											</div>
										</div>

										<textarea
											name="Interested In"
											type="text"
											style={{ display: "none" }}
											placeholder="Checkbox Values"
											value={
												checkboxValues.length
													? checkboxValues
													: "No Services Selected"
											}
										></textarea>

										<textarea
											name="Message/Description"
											placeholder="Write Message Here"
											className="text-area"
										></textarea>
										<button
											className="l-btn"
											onClick={saveCheckboxValues}
											disabled={state.submitting}
										>
											Send Message <FaArrowRight />
										</button>
									</form>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6">
								<div className="contact-box theme-bg-1 pt-60 pb-60 pl-35 pr-35 mr-40">
									<h3>
										Expanded Landscaping <br /> Services To
										Fit All Needs!
									</h3>
									<p>
										At Ramos Landscaping LLC, our mission is
										to collaborate with homeowners in
										transforming their outdoor spaces into
										attractive and functional parts of their
										homes.{" "}
									</p>
									<p>
										We specialize in creating landscapes
										that connect our community with the
										natural beauty of the Blue Ridge
										mountains. With our collaborative
										approach to landscape design, we work
										hand-in-hand with our clients to bring
										their ideas to life, utilizing our
										experience and expertise to beautify
										their own oasis.
									</p>
									<p>
										If you have an idea but don't know where
										to start, let's work together to turn it
										into a reality. Our goal is to bring joy
										to our clients as we create and beautify
										their outdoor spaces.{" "}
									</p>
									<ul className="mt-40">
										<li>
											<FaPhoneAlt />
											<span>
												Phone:{" "}
												<Link to="/contact">
													(828) 335-2141
												</Link>
											</span>
										</li>
										<li>
											<FaEnvelopeOpen />
											<span>
												Email:{" "}
												<Link to="/contact">
													info@ramoslandscapingservices.com
												</Link>
											</span>
										</li>
										{/* <li>
											<FaMapMarkerAlt />
											<span>
												Address: 45 East St, Asheville,
												NC 28803
											</span>
										</li> */}
										<li>
											<FaClock />
											<span>
												Mon - Sat: 8:00 am - 6:00 pm
											</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <div className="social-btn-area pb-110">
				<div className="container">
					<div className="row">
						<div className="col-xl-3 col-lg-3 col-md-6">
							<Link to="/" className="l-btn">
								Facebook
							</Link>
						</div>
						<div className="col-xl-3 col-lg-3 col-md-6">
							<Link to="/" className="l-btn">
								Twitter
							</Link>
						</div>
						<div className="col-xl-3 col-lg-3 col-md-6">
							<Link to="/" className="l-btn">
								Google Plus
							</Link>
						</div>
						<div className="col-xl-3 col-lg-3 col-md-6">
							<Link to="/" className="l-btn">
								Pinterest
							</Link>
						</div>
					</div>
				</div>
			</div> */}
		</>
	);
};

export default ContactForm;
