import React from "react";
import { Link } from "react-router-dom";

const CtaOne = () => {
	return (
		<>
			<div className="cta-area theme-bg-1 pt-60 pb-60">
				<div className="container">
					<div className="row">
						<div className="col-xl-12">
							<div className="cta-style-1-wrapper text-center">
								<h2>
									We invest our hard work for people just like
									you that <br /> insist on a great
									experience.
								</h2>
								<p>
									Call us at +1 (828) 335-2141 to schedule a
									free estimate
								</p>
								<Link to="/contact" className="cta-btn">
									Contact Us
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CtaOne;
